import ApiInstance from './ApiInstance.js'

export default {
  getLevels() {
    return ApiInstance({ requiresAuth: true }).get('/levels');
  },
  addNewLevel(Data) {
    return ApiInstance({ requiresAuth: true }).post('/levels', {level: Data});
  },
  editLevel(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/levels/${id}`, {level: Data[0]});
  },
  deleveLevel(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/levels/${id}`);
  }
}
